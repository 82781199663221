<template>
  <el-breadcrumb separator-class="el-icon-arrow-right">
    <el-breadcrumb-item :to="{ path: '/PointsMall' }">积分商城</el-breadcrumb-item>
    <el-breadcrumb-item>兑换记录</el-breadcrumb-item>
  </el-breadcrumb>
  <div class="pageBox">
    <!-- 1.筛选条件 -->
    <div class="searchBox">
      <el-row>
        <el-col :span="7" class="flex items-center">
          <div class="flex-none mr-8px">商品名称:</div>
          <el-input v-model="option.pointsMall" clearable placeholder="请输入商品名称" />
        </el-col>
        <el-col :span="7" class="ml-20px flex" v-if="couponType == 1">
          <el-button type="primary" @click="getTabList">查 询</el-button>
          <el-button @click="resetTable">重 置</el-button>
        </el-col>
        <el-col :span="7" class="flex items-center ml-20px" v-if="couponType == 2">
          <div class="flex-none mr-8px">兑换记录ID:</div>
          <el-input v-model="option.id" placeholder="请输入兑换记录ID" />
        </el-col>
        <el-col :span="7" class="flex items-center ml-20px" v-if="couponType == 2">
          <div class="flex-none mr-8px">会员ID:</div>
          <el-input v-model="option.memberID" placeholder="请输入会员ID" />
        </el-col>
      </el-row>
      <el-row class="mt20 mb-20px" v-if="couponType == 2">
        <el-col :span="7" class="flex items-center">
          <div class="flex-none mr-8px">归属门店:</div>
          <el-input v-model="option.shopName" placeholder="请输入归属门店" />
        </el-col>
        <el-col :span="7" class="ml-20px flex">
          <el-button type="primary" @click="getTabList">查 询</el-button>
          <el-button @click="resetTable">重 置</el-button>
        </el-col>
      </el-row>
    </div>
    <!--2. table  -->
    <el-config-provider :locale="zhCn">
      <el-table border stripe :data="data.table" style="width: 100%" :header-cell-style="{ background: 'rgb(242, 242, 242)', color: '#333' }" v-loading="tableLoading" element-loading-text="拼命加载中...">
        <el-table-column type="index" :index="indexMethod" width="50" style="text-align: center" />
        <el-table-column prop="id" label="兑换记录ID" v-if="couponType == 2" show-overflow-tooltip /> 
        <el-table-column prop="memberID" label="会员ID" show-overflow-tooltip />
        <el-table-column prop="shopName" label="归属门店" v-if="couponType == 2" /> 
        <el-table-column prop="pointsMall" label="商品名称" show-overflow-tooltip />
        <el-table-column prop="number" label="数量" />
        <el-table-column label="兑换状态">
          <template v-slot:default="scope">
            <el-tag type="success" v-if="scope.row.redemptionStatus == '0'">兑换成功</el-tag>
            <el-tag type="danger" v-if="scope.row.redemptionStatus == '1'">取消兑换</el-tag>
            <el-tag type="info" v-if="scope.row.redemptionStatus == '2'">兑换中</el-tag>
            <el-tag type="danger" v-if="scope.row.redemptionStatus == '3'">兑换异常</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="usingPoints" label="使用积分" />
        <el-table-column prop="redemptionTime" label="兑换时间" show-overflow-tooltip :formatter="formatCreationTime" />
        <el-table-column prop="platformAccount" label="平台账户" v-if="couponType == 2" /> 
        <el-table-column prop="exchangeTradeNo" label="关联积分记录ID" show-overflow-tooltip v-if="couponType == 2" /> 
        <el-table-column prop="exchangeNumber" label="关联充值记录ID" show-overflow-tooltip v-if="couponType == 2" /> 
        <el-table-column label="关联积分回退记录ID" show-overflow-tooltip v-if="couponType == 2">
          <template v-slot:default="scope">
            {{ scope.row.rollBackExchangeTradeNo || '-' }}
          </template>
        </el-table-column> 
        <el-table-column label="操作">
          <template #default="scope">
            <el-popconfirm title="您确定要补发积分吗？" confirm-button-text="是" cancel-button-text="否" iconColor="red" @confirm="reissueIntegralFun(scope.row)" v-if="scope.row.redemptionStatus == '3'">
              <template #reference>
                <el-button type="primary">补发积分</el-button>
              </template>
            </el-popconfirm>
            <div v-else>-</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 3.分页 -->
      <div class="paginationBox">
        <Pagination v-model:page="option.page" v-model:size="option.pageSize" :total="data.total" @pagination="handleCurrentChange" />
      </div>
    </el-config-provider>
  </div>
</template>

<!-- 本页的js -->
<script>
import { ref, reactive, onMounted } from "vue";
import { ListExchangeDetails, reissueIntegral } from "../../http/pointsMall";
import { FullTimeFormat } from "../../utils/common";
import { useRoute, useRouter } from "vue-router";
import zhCn from "element-plus/lib/locale/lang/zh-cn"
import Pagination from '@/components/pagination/index.vue'
import { ElMessage } from "element-plus";

export default {
  components: { Pagination },
  methods: {
    formatCreationTime(row) {
      return FullTimeFormat(row.creationTime);
    },
  },
  name: "Category",
  setup() {
    const route = useRoute();
    const router = useRouter();
    //params
    let pointsMallID = route.query.id;
    let couponType = route.query.couponType;
    onMounted(async () => {
      // 在onMounted生命周期钩子中调用GetAllListAsync方法
      if (!pointsMallID) {
        router.push({ name: "PointsMall" });
      }
    });
    //声明数据table表格的内容//
    let tableLoading = ref(false)
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
      ListData: [],
    });
    //声明查询条件
    let option = reactive({
      pointsMall: "",
      pointsId: pointsMallID,
      memberID: '', // 会员ID
      id: '', // 兑换记录ID
      shopName: '', // 门店名称
      page: 1,
      pageSize: 10
    });
    let indexMethod = (index) => {
      return index + (option.page - 1) * option.pageSize + 1;
    };
    let getCategoryListFun = async (obj) => {
      tableLoading.value = true
      let arr = await ListExchangeDetails(obj);
      tableLoading.value = false
      data.total = arr.totalCount;
      data.table = arr.items;
    };
    //初始化时，获取初始化数据
    getCategoryListFun({ 
      page: 1, 
      pageSize: 10, 
      pointsId: pointsMallID 
    });
    let resetTable = () => {
      option.pointsMall = ''
      option.memberID = ''
      option.id = ''
      option.shopName = ''
      option.page = 1
      option.pageSize = 10
      getCategoryListFun(option);
    }
    //点击页码获取数据
    let handleCurrentChange = () => {
      getCategoryListFun(option);
    };
    //查询方法,此处需要根据条件到后台进行筛选
    let getTabList = async () => {
      await getCategoryListFun(option);
    };
    // 补发积分
    let reissueIntegralFun = async(row) => {
      let data = await reissueIntegral({ id: row.id })
      if(data.code == 200) {
        ElMessage.success('补发成功')
        resetTable()
      } else {
        ElMessage.error(data.message)
      }
    } 
    return {
      reissueIntegralFun,
      data,
      tableLoading,
      handleCurrentChange,
      getTabList,
      option,
      indexMethod,
      couponType,
      resetTable,
      zhCn
    };
  },
};
</script>
<!-- 本页的css -->
<style lang="scss" scoped>
::v-deep {
  .paginationBox {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    .el-pagination.is-background .btn-prev, .el-pagination.is-background .btn-next {
      padding: 8px !important;
    }
  }
}
.pageBox {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #f5f5f5;
}

.searchBox {
  margin-bottom: 20px;
}

.paginationBox {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}
</style>
